import React, { useEffect } from 'react';
import Head from 'next/head';
import CitationInput from 'components/app/citation-input';
import CitationOutput from 'components/app/citation-output';
import citationForms from 'types/citation-forms';
import * as CitationActions from 'redux/modules/citation-module';
import * as CitationListActions from 'redux/modules/citation-list-module';
import { useAppDispatch, Store } from 'redux/store';
import CitationTypes from 'types/citation-types';
import { useSelector, useStore } from 'react-redux';
import { getIsCitationOpen } from 'redux/selectors/citation-selector';
import CitationAddSuggestion from './citation-add-suggestion';
import enableClarity from 'lib/enable-clarity';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import { saveCurrentList } from 'models/citation-list-model';
import { citationFromRouterQuery } from 'lib/chrome';
import { citationsFromRouterQuery } from 'lib/gdocs';
import arrayFirstOrSelf from 'lib/array-first-or-self';
import { setGdocsExport } from 'redux/modules/auth-module';
import { reportGeneralException } from 'lib/sentry';
import enableHotjar from 'lib/enable-hotjar';
import css from './app.module.css';
import cx from 'classnames';

interface AppProps {
  type?: string;
  query?: string;
  forceCitationStyle?: string;
  error?: Record<string, unknown>;
  showFooter?: boolean;
}
const App: React.FC<AppProps> = (props: AppProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const store = useStore();
  const isCitationOpen = useSelector((state: Store) =>
    getIsCitationOpen(state),
  );

  const handleChangeCitationInputType = (
    citationInputType: CitationTypes,
  ): void => {
    const cslCitationType = citationForms[citationInputType];
    if (cslCitationType) {
      dispatch(
        CitationActions.editCurrentCitation({
          type: cslCitationType.type,
        }),
      );
      dispatch(CitationActions.setCitationType(citationInputType));
    }
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const randomValue = Math.floor(Math.random() * 20);
      if (randomValue === 0) {
        enableClarity();
      } else if (randomValue === 1) {
        enableHotjar();
      }
    }
    if (typeof window !== 'undefined') {
      if (props.type) {
        dispatch(CitationActions.startNewCitation(props.type));
        router.replace(router.pathname, router.pathname, { shallow: true });
      }
    }
  }, [dispatch, props.type, router]);

  useEffect(() => {
    if (props.error) {
      reportGeneralException('Got error from getMe', props.error);
    }
  }, [props.error]);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const citation = citationFromRouterQuery(router.query);
    if (!citation) {
      return;
    }
    dispatch(CitationActions.startNewCitation(citation.citationType));
    dispatch(
      CitationActions.editCurrentCitation({ ...citation.data, id: uuidv4() }),
    );
    dispatch(CitationActions.fillManually());
    saveCurrentList(store);
    router.replace(router.pathname, router.pathname, { shallow: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, store]); // we don't want to include router in the deps because it will cause an infinite loop

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const citations = citationsFromRouterQuery(router.query);
    if (citations.length === 0) {
      return;
    }
    dispatch(CitationActions.addCitationsFromRouterQuery(citations));
    saveCurrentList(store);

    router.replace(router.pathname, router.pathname, { shallow: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, store]); // exclude router from deps to avoid infinite loop

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const gdocId = arrayFirstOrSelf(router.query.gdocId);
    const token = arrayFirstOrSelf(router.query.token);
    if (gdocId && token) {
      dispatch(setGdocsExport(true));
      dispatch(
        CitationListActions.addGdocCitationsToCurrentList({ gdocId, token }),
      );
      router.replace(router.pathname, router.pathname, { shallow: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]); // exclude router from deps to avoid infinite loop

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const wordId = arrayFirstOrSelf(router.query.wordId);
    if (wordId) {
      dispatch(CitationActions.addWordCitationsToCurrentList(wordId)).then(
        () => {
          router.replace(router.pathname, router.pathname, { shallow: true });
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]); // exclude router from deps to avoid infinite loop
  return (
    <div>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=0"
        />
      </Head>
      {props.error !== undefined ? (
        <p
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: '100vh',
          }}
        >
          Getting citations failed. Check your internet connection and restart
          Bibcitation.
        </p>
      ) : (
        <div className={css.citations}>
          <div className={css.container}>
            {isCitationOpen ? (
              <CitationInput
                onChangeCitationInputType={handleChangeCitationInputType}
                query={props.query}
                type={props.type}
              />
            ) : (
              <CitationAddSuggestion showFooter={props.showFooter} />
            )}
            <div
              className={cx(
                css.citationOutputContainer,
                isCitationOpen
                  ? css.citationOpenDisplay
                  : css.citationClosedDisplay,
              )}
            >
              <CitationOutput forceCitationStyle={props.forceCitationStyle} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
