import { useRouter } from 'next/router';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './app/app';
import Head from 'next/head';
import Page from './common/page';
import arrayFirstOrSelf from 'lib/array-first-or-self';
import css from './styles/citation-generator-page.module.css';

type CitationGeneratorPageProps = {
  title: string;
  metaDescription?: string;
  forceCitationStyle?: string;
  error?: Record<string, unknown>;
  guideStyle?: string;
};

const CitationGeneratorPage: React.FC<
  React.PropsWithChildren<CitationGeneratorPageProps>
> = ({
  title,
  metaDescription,
  forceCitationStyle,
  error,
  guideStyle,
  children,
}) => {
  const router = useRouter();
  return (
    <Page
      pageName="Citation Generator"
      pageViewEventProps={guideStyle ? { guideStyle: guideStyle } : undefined}
    >
      <Head>
        <title>{title}</title>
        {metaDescription !== undefined && (
          <meta name="description" content={metaDescription} />
        )}
      </Head>
      <DndProvider backend={HTML5Backend}>
        <App
          forceCitationStyle={forceCitationStyle}
          query={arrayFirstOrSelf(router.query.query)}
          type={arrayFirstOrSelf(router.query.type)}
          error={error}
          showFooter={!children} // do not show footer if children are present
        />
      </DndProvider>
      {!!children && <div className={css.content}>{children}</div>}
    </Page>
  );
};

export default CitationGeneratorPage;
