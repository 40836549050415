import createWebUrl from 'lib/create-web-url';
import { NextRouter } from 'next/router';
import { Citation } from 'redux/modules/citation-module';

export const citationFromRouterQuery = (
  query: NextRouter['query'],
): Citation | undefined => {
  const citationJSON = query.citation as string;
  if (!citationJSON) {
    return;
  }
  let citation;
  try {
    citation = JSON.parse(citationJSON);
  } catch (error) {
    if (error instanceof SyntaxError) {
      return;
    }
    throw error;
  }
  return citation as Citation;
};

export const makeAddCitationUrl = (citation: Citation): string =>
  createWebUrl(`/?citation=${encodeURIComponent(JSON.stringify(citation))}`);
