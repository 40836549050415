import { NextPage } from 'next';
import CitationGeneratorPage from 'components/citation-generator-page';

const Home: NextPage<{ error: Record<string, unknown> }> = ({ error }) => {
  return (
    <CitationGeneratorPage
      title="FREE Citation, Bibliography & Works Cited Generator"
      error={error}
    />
  );
};

export default Home;
